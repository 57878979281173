import React from "react";
import { Link } from "react-router-dom";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, NavItem, Navbar } from "reactstrap";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { selectLeaderboardOpen, toggleLeaderboard } from "./modalSlice";
import { selectRaceStatus, selectresults } from "../race/raceSlice";
import Confetti from 'react-confetti'
const Leaderboard = () => {


    const LeaderboardModalIsOpen = useAppSelector(selectLeaderboardOpen);

    const raceStatus = useAppSelector(selectRaceStatus);
    const raceDonationResults = useAppSelector(selectresults);
    const dispatch = useAppDispatch();

    let KinderWinner =
    {
        "grade": "Kindergarten",
        "total": raceDonationResults.K_total
    };

    let SecondWinner =
    {
        "grade": "2nd grade",
        "total": raceDonationResults.Second_total
    };

    let leaderdata = [
        // {
        //     "grade": "Kindergarten",
        //     "total": raceDonationResults.K_total
        // },
        {
            "grade": "1st grade",
            "total": raceDonationResults.First_total
        },
        {
            "grade": "2nd grade",
            "total": raceDonationResults.Second_total
        },
        {
            "grade": "3rd grade",
            "total": raceDonationResults.Third_total
        },
        {
            "grade": "4th grade",
            "total": raceDonationResults.Fourth_total
        },
        {
            "grade": "5th grade",
            "total": raceDonationResults.Fifth_total
        },
        {
            "grade": "6th grade",
            "total": raceDonationResults.Sixth_total
        },
    ];

    if (leaderdata.length === 6) {
        leaderdata.sort((a, b) => b.total - a.total);
        // leaderdata.unshift(SecondWinner);
        leaderdata.unshift(KinderWinner);

        return (
            <>
                {LeaderboardModalIsOpen && <Confetti>

                    {/* width={50}
                    height={1050} */}
                </Confetti>
                }
                <Modal
                    isOpen={LeaderboardModalIsOpen}
                    toggle={() => { dispatch(toggleLeaderboard()) }}
                    backdrop={"static"}
                    keyboard={false}
                    className="searchmodal leaderboard"
                >
                    <ModalHeader toggle={() => { dispatch(toggleLeaderboard()) }}>
                        {raceStatus === "loading" && <div className="spinner-grow text-warning" role="status">
                        </div>}
                        Top Fundraising Grades
                    </ModalHeader>
                    <ModalBody>
                        <div>
                            <ol style={{ listStyleType: "none", paddingLeft: 0 }}>
                                <li>
                                    <Confetti>

                                        {/* width={50}
                                        height={1050} */}
                                    </Confetti>
                                    <span
                                        className="raceInfo1">
                                        <img
                                            alt="" src="https://www.shutterstock.com/image-vector/isolated-happy-slice-pizza-emote-260nw-1127962724.jpg"
                                            className="raceInfo2"
                                            title="" />

                                    </span>

                                    <h3>{leaderdata[0].grade}</h3>
                                    <small>{"$" + leaderdata[0].total.toFixed(2)}</small>
                                </li>
                                <li>
                                    {/* <span
                                        className="raceInfo1">
                                        <img
                                            alt="" src="https://www.shutterstock.com/image-vector/isolated-happy-slice-pizza-emote-260nw-1127962724.jpg"
                                            className="raceInfo2"
                                            title="" />

                                    </span> */}
                                    <mark>{leaderdata[1].grade}</mark>
                                    <small>{"$" + leaderdata[1].total.toFixed(2)}</small>
                                </li>
                                <li>
                                    <mark>{leaderdata[2].grade}</mark>
                                    <small>{"$" + leaderdata[2].total.toFixed(2)}</small>
                                </li>
                                <li>
                                    <mark>{leaderdata[3].grade}</mark>
                                    <small>{"$" + leaderdata[3].total.toFixed(2)}</small>
                                </li>
                                <li>
                                    <mark>{leaderdata[4].grade}</mark>
                                    <small>{"$" + leaderdata[4].total.toFixed(2)}</small>
                                </li>
                                <li>
                                    <mark>{leaderdata[5].grade}</mark>
                                    <small>{"$" + leaderdata[5].total.toFixed(2)}</small>
                                </li>
                                <li>
                                    <mark>{leaderdata[6].grade}</mark>
                                    <small>{"$" + leaderdata[6].total.toFixed(2)}</small>
                                </li>
                            </ol>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                    </ModalFooter>
                </Modal >
                {raceStatus === "loading" && <div className="spinner-grow text-warning" role="status">
                </div>
                }
                {
                    raceStatus !== "loading" && <Button id="donate" color="danger" className="btn-xs" onClick={() => {
                        dispatch(toggleLeaderboard());
                    }}>
                        Leader Board
                    </Button>
                }
            </>

        );
    } else {
        return <></>;
    }
};

export default Leaderboard;



