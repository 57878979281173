import React from "react";
import { Link } from "react-router-dom";
import { Modal, ModalBody, ModalFooter, ModalHeader, NavItem, Navbar } from "reactstrap";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { selectRaceInfoOpen, toggleRaceInfo } from "./modalSlice";

const RaceInfo = () => {


    const RaceInfoModalIsOpen = useAppSelector(selectRaceInfoOpen);

    const dispatch = useAppDispatch();

    return (
        <Modal
            isOpen={RaceInfoModalIsOpen}
            toggle={() => { dispatch(toggleRaceInfo()) }}
            backdrop={"static"}
            keyboard={false}
            className="searchmodal"
        >
            <ModalHeader toggle={() => { dispatch(toggleRaceInfo()) }}>
                RACE FOR EDUCATION 2024-2025
            </ModalHeader>
            <ModalBody>
                <p className="c8 c12">
                    <span className="c18">Lehman-Jackson Elementary students will soon be participating in our annual
                        Race for Education!</span>
                    <span className="c15 c16"> Student participants will either
                        collect donations from family and friends or elect to &ldquo;opt out&rdquo; with a flat donation ($50 per
                        child or $75 per family). On Friday October 4th , students will walk or jog around our &frac14; mile track as
                        many times as they are able during their 45-minute &ldquo;race&rdquo; to help earn the donations.
                        &nbsp;</span></p>
                <p className="c1 c12"><span className="c15 c16"></span></p>
                <p className="c8 c12"><span className="c15 c16">Race for Education is our most important fundraiser of the year. Funds
                    raised from this event will help to cover the costs of all LJE-PTO sponsored activities, including field
                    trips, assemblies, dances, RIF events, the summer reading program, and other activities that support student
                    learning.</span></p>
                <p className="c1 c12"><span className="c15 c9"></span></p>
                <p className="c0"><span className="c18 c21">Our goal this year is to raise </span><span className="c20 c21">$15,000</span><span
                    className="c15 c18 c21">!</span></p>
                {/* <p className="c0">
                    <span className="c18">Kindergarten locked in a Pizza Party by raising the most funds by 10/4/2024 </span>
                    <span className="c18">&nbsp;Congratulations! </span>
                    <span className="c18">We won't let rain bring us down!  Kindergarten locked in a pizza party!  One more grade can win. We will be giving away another pizza party to the next top earning class 1st-6th. All donations collected this year up to our rain date 10/20 will be counted. Keep them coming in!</span>
                    <span
                        className="raceInfo1"><img
                            alt="" src="https://www.shutterstock.com/image-vector/isolated-happy-slice-pizza-emote-260nw-1127962724.jpg"
                            className="raceInfo2"
                            title="" /></span></p> */}

                <p className="c0 c6"><span className="c15 c16"></span></p>
                <p className="c8 c12"><span className="c20">All students</span><span className="c15 c16">&nbsp;are invited to join in the events
                    on Friday October 4th regardless of participation level. Each student will receive snacks and is eligible to earn
                    bracelets on race day!!! The PTO will also provide music!!! </span></p>
                <p>
                    We are going to keep racing throughout October. Fun was had by all at our race for education! Please continue to share our link https://lje-pto.com/race or donations can continue to be sent into school labeled with name and Race for Education.
                    We have decided to continue donations throughout October. While Kindergarten has already locked in their pizza party for 1st place, we will provide an additional pizza party for whichever grade comes in 2nd place.
                    Parents, Family and Friends who have donated and continue to donate up until Oct 31st will be automatically be entered to win a $100 Tomisino's giftcard. Winner for the 2nd Pizza Party and Giftcard will be announced on Friday November 1st.
                </p>
                {/* <p className="c0"><span className="c18">The grade that raises the most funds by</span><span className="c20">&nbsp;Friday Sept.
                    27th</span><span className="c18">&nbsp;will receive a grand prize pizza party! </span><span
                        className="raceInfo1"><img
                            alt="" src="https://www.shutterstock.com/image-vector/isolated-happy-slice-pizza-emote-260nw-1127962724.jpg"
                            className="raceInfo2"
                            title="" /></span></p> */}
            </ModalBody>
            {/* <ModalBody>
                <p className="c8 c12">
                    <span className="c18">Lehman-Jackson Elementary students will soon be participating in our annual 
                    Race for Education!</span>
                    <span className="c15 c16"> Student participants will either
                        collect donations from family and friends or elect to &ldquo;opt out&rdquo; with a flat donation ($50 per
                        child or $75 per family). On Friday October 4th , students will walk or jog around our &frac14; mile track as
                        many times as they are able during their 45-minute &ldquo;race&rdquo; to help earn the donations.
                        &nbsp;</span></p>
                <p className="c1 c12"><span className="c15 c16"></span></p>
                <p className="c8 c12"><span className="c15 c16">Race for Education is our most important fundraiser of the year. Funds
                    raised from this event will help to cover the costs of all LJE-PTO sponsored activities, including field
                    trips, assemblies, dances, RIF events, the summer reading program, and other activities that support student
                    learning.</span></p>
                <p className="c1 c12"><span className="c15 c9"></span></p>
                <p className="c0"><span className="c18 c21">Our goal this year is to raise </span><span className="c20 c21">$15,000</span><span
                    className="c15 c18 c21">!</span></p>
                <p className="c0">
                    <span className="c18">Kindergarten and Second Grade locked in a Pizza Party by raising the most funds by 10/20/2024 </span>
                    <span className="c18">&nbsp;Congratulations! </span>
                    { <span className="c18">We won't let rain bring us down!  Kindergarten locked in a pizza party!  One more grade can win. We will be giving away another pizza party to the next top earning class 1st-6th. All donations collected this year up to our rain date 10/20 will be counted. Keep them coming in!</span> }
                    <span className="c18">Race for education was a success!  Donations will continue to be accepted. Keep them coming in!  Funds
                    raised from this event will help to cover the costs of all LJE-PTO sponsored activities, including field
                    trips, assemblies, dances, RIF events, the summer reading program, and other activities that support student
                    learning.</span>
                    <span
                        className="raceInfo1"><img
                            alt="" src="https://www.shutterstock.com/image-vector/isolated-happy-slice-pizza-emote-260nw-1127962724.jpg"
                            className="raceInfo2"
                            title="" /></span></p>
                { <p className="c0"><span className="c18">The grade that raises the most funds by</span><span className="c20">&nbsp;Friday Sept.
                    27th</span><span className="c18">&nbsp;will receive a grand prize pizza party! </span><span
                        className="raceInfo1"><img
                            alt="" src="https://www.shutterstock.com/image-vector/isolated-happy-slice-pizza-emote-260nw-1127962724.jpg"
                            className="raceInfo2"
                            title="" /></span></p> }
                <p className="c0 c6"><span className="c15 c16"></span></p>
                <p className="c8 c12"><span className="c20">All students</span><span className="c15 c16">&nbsp;are invited to join in the events
                    on Friday October 4th regardless of participation level. Each student will receive snacks and is eligible to earn
                    bracelets on race day!!! The PTO will also provide music!!! </span></p>
            </ModalBody> */}
            <ModalFooter>
            </ModalFooter>
        </Modal>

    );
};

export default RaceInfo;



